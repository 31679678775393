import { request } from '../contexts';
import { Metadata } from './energy-locations-service';

export type OverrideOptions = {
  overrideHoldType: 'HEAT' | 'COOL';
  overrideSetPoint: number;
  overrideDuration: number;
};

export type BulkEditMinProps = {
  uuid: string;
  metadata: {
    occupiedHeatSetPoint?: string;
    occupiedCoolSetPoint?: string;
    unoccupiedHeatSetPoint?: string;
    unoccupiedCoolSetPoint?: string;
    model: string;
  };
};
export class EnergyDeviceService {
  public static async browseDeviceByUUID(deviceUUID: string) {
    const { data } = await request({
      method: 'GET',
      url: '/api/v2/energy-devices',
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      params: {
        uuids: deviceUUID,
      },
    });

    return data;
  }

  public static async updateHvacDevice({
    deviceUUID,
    metadata,
  }: {
    deviceUUID: string;
    metadata: Metadata;
  }) {
    const { data } = await request({
      method: 'PUT',
      url: `/api/v2/energy-devices/${deviceUUID}`,
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      data: {
        metadata,
      },
    });
    return data;
  }

  public static async bulkEditHVAC(hvacList: BulkEditMinProps[]) {
    const promises = hvacList.map((hvac) => {
      return request({
        method: 'PUT',
        url: `/api/v2/energy-devices/${hvac.uuid}`,
        baseURL:
          process.env.ENERGY_GATEWAY_DOMAIN ??
          'https://gateway.energy.dev.helloatka.com',
        data: {
          metadata: hvac.metadata,
        },
      });
    });

    const data = await Promise.all(promises);

    return data;
  }

  public static async override({
    deviceUUID,
    active,
    options = {} as OverrideOptions,
  }: {
    deviceUUID: string;
    active: boolean;
    options?: OverrideOptions;
  }) {
    const { data } = await request({
      method: 'POST',
      url: `/api/v2/energy-devices/toggle-active/${deviceUUID}`,
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      data: {
        active,
        ...options,
      },
    });
    return data;
  }
}
