import { roundNumber, toCelsius, toFahrenheit } from '@coinspect/utils';
import moment from 'moment';

import { AlertType } from '../hooks';
import { TempUnits } from '../services';

export const toTwoDecimal = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

// This converts F to C since we save temperature in C
export const convertToSavableTriggerValue = (
  value: number | null | undefined,
  field: string,
  preferredTempUnit: TempUnits,
): number | null => {
  const defaultValue = 15; // default value for temperature (celsius) or non-temperature
  if (value === undefined || value === null) {
    return defaultValue;
  }

  if (preferredTempUnit === 'c' || field !== 'temperature') {
    return value;
  }

  return roundNumber(toCelsius(value), 2);
};

export const convertToPreferredUnit = (
  value: number,
  field: AlertType,
  prefTempUnit?: TempUnits | undefined,
): number | null => {
  const unit = prefTempUnit ?? 'f';

  if (unit === 'c' || field !== 'temperature') {
    return toTwoDecimal(+value);
  }

  return toTwoDecimal(toFahrenheit(value));
};

// this expects the temp as originally Fahrenheit
// this is for HVAC related temperatures
export const oppositePreferredUnit = (
  value: number,
  field: string,
  prefTempUnit?: TempUnits | undefined,
): number => {
  const unit = prefTempUnit ?? 'c';

  if (unit === 'f' || field !== 'temperature') {
    return toTwoDecimal(+value);
  }
  return toTwoDecimal(toCelsius(value));
};

export const setVisualTemperature = (
  type: string | undefined,
  temperature: number | undefined,
  preferredUnit?: TempUnits,
): string => {
  let temp;

  if (!temperature) {
    return `- \u00B0${preferredUnit ? preferredUnit.toUpperCase() : '\u00B0F'}`;
  }

  // HVAC temperatures are always in F at this time
  if (type === 'hvac' && preferredUnit === 'f') {
    temp = roundNumber(temperature, 2);
  } else if (type === 'hvac' && preferredUnit === 'c') {
    temp = roundNumber(toCelsius(temperature), 2);
  } else {
    temp = convertToPreferredUnit(temperature, 'temperature', preferredUnit);
  }

  return `${temp?.toFixed(1) ?? '-'} \u00B0${
    preferredUnit ? preferredUnit.toUpperCase() : '\u00B0F'
  }`;
};

export const getVisualHumidity = (value?: number | null) => {
  if (!value && value !== 0) {
    return '-';
  }
  return `${Math.round(value)} %`;
};

export const getVisualTemperature = (
  type?: string,
  value?: number | null,
  pref?: TempUnits,
) => {
  if (!value && value !== 0) {
    return '-';
  }
  return setVisualTemperature(type, value, pref as TempUnits);
};

export const getAlertBuffer = (
  value: number,
  tempUnit: string,
  truncate = true,
) => {
  const convertedValue = tempUnit === 'c' ? value : value * (9 / 5);
  return truncate ? toTwoDecimal(convertedValue) : convertedValue;
};

export const isReadingOutdated = (reading?: string) => {
  const readingDate = moment(reading);
  return moment().diff(readingDate, 'minutes') > 120;
};
