import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Text } from '../text';

interface CheckboxProps {
  value: string;
  label: string;
  onChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
}

const CustomCheckbox = styled.div`
  .container {
    display: flex;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
    align-items: center;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #2b2b2b;
    border: 1px solid white;
    border-radius: 2px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: rgba(255, 255, 255, 0.08);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: var(--new-primary-color);
    border-color: var(--new-primary-color);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 0px;
    width: 7px;
    height: 13px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .container svg {
    margin-right: 3px;
    height: 14px;
  }

  .container input:disabled ~ .checkmark {
    border: 1px solid #6a7070;
    cursor: not-allowed;
  }
`;

export const Checkbox = (props: CheckboxProps) => {
  const {
    value,
    onChange,
    checked = false,
    label,
    icon,
    disabled = false,
    containerStyle = {},
  } = props;

  return (
    <CustomCheckbox style={containerStyle}>
      <label
        className="container"
        htmlFor={value}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        {icon ? icon : null}
        <Text useNew>{label}</Text>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(value, e)}
          value={value}
          id={value}
          name={value}
          disabled={disabled}
        />
        <span className="checkmark" />
      </label>
    </CustomCheckbox>
  );
};
